import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    config.withCredentials = true;
    return config;
}, (err) => {
    return Promise.reject(err);
});


export interface IServersResponse<T> {
    error: boolean,
    message?: string,
    result?: T,
}


export const apiUrls = {
    "v1": {
        "auth": {
            "signIn": `${apiBaseUrl}/v1/auth/sign-in`,
            "signOut": `${apiBaseUrl}/v1/auth/sign-out`,
            "requestResetPassword": `${apiBaseUrl}/v1/auth/request-reset-password`,
            "resetPassword": `${apiBaseUrl}/v1/auth/reset-password`,
        },
        "users": {
            "/me": `${apiBaseUrl}/v1/users/me`,
        },
        "subscriptions": {
            "/": `${apiBaseUrl}/v1/subscriptions`,
        },
        "transactions": {
            "/": `${apiBaseUrl}/v1/transactions`
        },
        "subscribers": {
            "/": `${apiBaseUrl}/v1/subscribers`
        },
        "settings": {
            "/": `${apiBaseUrl}/v1/settings`
        },
        "stats": {
            "transaction": {
                "count": `${apiBaseUrl}/v1/stats/transactions/count`,
                "daysCount": `${apiBaseUrl}/v1/stats/transactions/days-count`,
                "months": `${apiBaseUrl}/v1/stats/transactions/months`
            },
            "fee": {
                "/": `${apiBaseUrl}/v1/stats/fee`
            }
        },
    }
};

export default axios;

