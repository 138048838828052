import * as React from "react"
import {connect} from "react-redux";
import Loadable from 'react-loadable';
import styled from 'styled-components';
import {Route, Switch} from "react-router-dom";
import {initialisingSelector} from "../modules/app/AppSelector";
import {userSelector} from "../modules/user/UserSelector";
import Initialising from "./components/Initialising";
import {PrivateRoute, PublicRoute} from "../services/Router";
import {init} from "../modules/app/AppActions";
import SiteNavBar from "./components/SiteNavBar";
import {IUser} from "../modules/user/UserTypes";
import {signOut} from "../modules/user/UserActions";


const SignInComponent = Loadable({
    loader: () => import('./pages/authentication/SignIn'),
    loading: Initialising
});

const HomeComponent = Loadable({
    loader: () => import('./pages/Home'),
    loading: Initialising
});

const StatsComponent = Loadable({
    loader: () => import('./pages/Stats'),
    loading: Initialising
});

const RequestResetPasswordComponent = Loadable({
    loader: () => import('./pages/authentication/RequestResetPassword'),
    loading: Initialising
});

const ResetPasswordComponent = Loadable({
    loader: () => import('./pages/authentication/ResetPassword'),
    loading: Initialising
});

const ErrorComponent = Loadable({
    loader: () => import('./pages/Error'),
    loading: Initialising
});

const SubscribersComponent = Loadable({
    loader: () => import('./pages/Subscribers'),
    loading: Initialising
});

const SubscriptionsComponent = Loadable({
    loader: () => import('./pages/Subscriptions'),
    loading: Initialising
});
const TransactionsComponent = Loadable({
    loader: () => import('./pages/Transactions'),
    loading: Initialising
});

const SettingsComponent = Loadable({
    loader: () => import('./pages/Settings/index'),
    loading: Initialising
});


interface IRootContainerProps {
    init?: () => void;
    signOut?: () => void;
    initialising?: boolean;
    user?: IUser;
}

interface IRootContainerState {
    name: string;
}


const AppContainer = styled.div`
    height: 100%;
    padding-bottom: 20px;
`;


class RootContainer extends React.Component<IRootContainerProps, IRootContainerState> {

    constructor(props: IRootContainerProps) {
        super(props);
        this.state = {
            name: "Payment system"
        };
    }

    componentDidMount(): void {
        if (this.props.init) {
            this.props.init();
        }
    }
    render() {

        const {user, signOut} = this.props;

        let isLoggedIn: boolean = false;

        if (!this.props.initialising && user && user.userId) {
            isLoggedIn = true;
        }

        const doSignOut = (): void => {
            if (signOut) {
                signOut();
            }
        };


        return <AppContainer>

            {isLoggedIn && <SiteNavBar doSignOut={doSignOut} user={user}/>}

            {this.props.initialising && <Initialising/> }

            {!this.props.initialising && <Switch>

                <Route exact path="/">
                    <HomeComponent/>
                </Route>

                <PrivateRoute isLoggedIn={isLoggedIn} path="/subscribers">
                    <SubscribersComponent/>
                </PrivateRoute>

                <PrivateRoute isLoggedIn={isLoggedIn} path="/subscriptions">
                    <SubscriptionsComponent/>
                </PrivateRoute>

                <PrivateRoute isLoggedIn={isLoggedIn} path="/settings">
                    <SettingsComponent/>
                </PrivateRoute>

                <PrivateRoute isLoggedIn={isLoggedIn} path="/transactions">
                    <TransactionsComponent/>
                </PrivateRoute>


                <PrivateRoute isLoggedIn={isLoggedIn} path="/stats">
                    <StatsComponent/>
                </PrivateRoute>


                <PublicRoute isLoggedIn={isLoggedIn} path="/sign-in">
                    <SignInComponent/>
                </PublicRoute>

                <PublicRoute isLoggedIn={isLoggedIn} path="/reset-password/:recoveryToken">
                    <ResetPasswordComponent/>
                </PublicRoute>

                <PublicRoute isLoggedIn={isLoggedIn} path="/request-reset-password">
                    <RequestResetPasswordComponent/>
                </PublicRoute>

                <Route path="*">
                    <ErrorComponent code={404}/>
                </Route>

            </Switch>}
        </AppContainer>;
    }

}


const mapStateToProps = () => {
    const makeInitialising = initialisingSelector();
    const makeUser = userSelector();
    return (state: any) => {
        return {
            initialising: makeInitialising(state),
            user: makeUser(state),
        }
    }
};

const mapDispatchToProps: any = (dispatch: any) => ({
    init: () => dispatch(init()),
    signOut: () => dispatch(signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
