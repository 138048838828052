import * as React from 'react';
import {Route, Redirect} from "react-router-dom";


export function PrivateRoute({children, ...rest}: {isLoggedIn: boolean, children?: any, path: any}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                rest.isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/sign-in",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}


export function PublicRoute({children, ...rest}: any) {
    return (
        <Route
            {...rest}
            render={() =>
                !rest.isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/stats"
                        }}
                    />
                )
            }
        />
    );
}
