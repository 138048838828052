import createSagaMiddleware, {SagaMiddleware} from "redux-saga";
import {applyMiddleware, createStore, Store} from "redux";
import {createLogger} from "redux-logger";
import reducer from "./Reducer";
import userSaga from "../modules/user/UserSaga";
import appSaga from "../modules/app/AppSaga";


export const configure = () => {

    const sagaMiddleware: SagaMiddleware<any> = createSagaMiddleware({
        onError: (error) => {
            console.log(error);
        }
    });

    const middleware: any[] = [sagaMiddleware];
    if (process.env && process.env.NODE_ENV !== "production") {
        middleware.push(createLogger({
            collapsed: false,
        }));
    }
    const store: Store<any> = createStore(reducer, applyMiddleware(...middleware));

    sagaMiddleware.run(userSaga);
    sagaMiddleware.run(appSaga);


    return store;
};

let store: Store<any>;

export const getStore = () => {
    if (!store) {
        store = configure();
    }
    return store;
};
