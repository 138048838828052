import {actions} from "./AppReducer";
import {takeLatest, put} from "redux-saga/effects";
import {IAppActions, pingSucceed} from "./AppActions";
import {init as userInit} from "../user/UserActions";

function* pingHandler({payload}: IAppActions) {
    try {
        yield put(pingSucceed("success"));
    } catch (e) {

    }
}

function* initHandler() {
    yield put(userInit());
}


function* appSaga(): any {
    yield takeLatest(actions.PING, pingHandler);
    yield takeLatest(actions.INIT, initHandler);
}

export default appSaga;
