import React from 'react';
import RootContainer from './containers/RootContainer';
import {Provider} from 'react-redux';
import {getStore} from "./services/Store";
import {BrowserRouter} from 'react-router-dom';
import Language from "./services/Language";
import {createGlobalStyle} from "styled-components";
import {ToastContainer} from "react-toastify";

Language.init();


const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,400,700&display=swap');
    body {
    margin: 0;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background-color: #F9F9F9;  
  }
  
  .table td, .table th {
    vertical-align: middle!important;
}
`;

function App() {
    return (
        <Provider store={getStore()}>
            <BrowserRouter>
                <GlobalStyles/>
                <ToastContainer />
                <RootContainer/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
