import axios, {apiUrls, IServersResponse} from '../../services/Request'
import {AxiosResponse} from 'axios';

export interface ISignInResult {
    userId: number,
    email: string,
    createdAt: string,
    customer: {
        customerId: number,
        name: string,
    }
    accessToken: string,

}

export interface IRequestResetPasswordResult {
    isRequested: boolean,
}

export interface IValidateResetPasswordResult {
    isValid: boolean,
}

export interface IResetPasswordResult {
    isUpdated: boolean,
}


export default class UserRequests {

    public static async signIn({email, password, isRememberMeChecked}): Promise<IServersResponse<ISignInResult>> {
        let signingRequest: AxiosResponse = await axios.post(apiUrls.v1.auth.signIn, {
            email, password, isRememberMeChecked
        });
        return signingRequest.data
    }

    public static async signOut(): Promise<any> {
        return axios.post(apiUrls.v1.auth.signOut);
    }


    public static async retrieveLoggedUser(): Promise<IServersResponse<ISignInResult>> {
        let loggedUserRequest: AxiosResponse = await axios.get(apiUrls.v1.users["/me"]);
        return loggedUserRequest.data;
    }


    public static async requestResetPassword({email}): Promise<IServersResponse<IRequestResetPasswordResult>> {
        try {
            const result: AxiosResponse = await axios.post(apiUrls.v1.auth.requestResetPassword, {
                email
            });
            return result.data;
        } catch (e) {
            return {
                error: true,
                message: "NETWORK_ERROR",
            }
        }
    }

    public static async validateRecoveryToken({recoveryToken}): Promise<IServersResponse<IValidateResetPasswordResult>> {
        try {
            const result: AxiosResponse = await axios.get(`${apiUrls.v1.auth.resetPassword}/${recoveryToken}`);
            return result.data;
        } catch (e) {
            return {
                error: true,
                message: "NETWORK_ERROR",
            }
        }
    }

    public static async resetPassword({recoveryToken, password, repeatPassword}): Promise<IServersResponse<IResetPasswordResult>> {
        try {
            const result: AxiosResponse = await axios.put(`${apiUrls.v1.auth.resetPassword}/${recoveryToken}`, {
                recoveryToken, password, repeatPassword
            });
            return result.data;
        } catch (e) {
            return {
                error: true,
                message: "NETWORK_ERROR",
            }
        }
    }


}
