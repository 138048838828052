import {IUserActions} from "./UserActions";
import {ICustomer, IUser} from "./UserTypes";

interface IUserReducerActions {

    SIGN_IN: any;
    SIGN_IN_SUCCEED: string;
    SIGN_IN_FAILED: string;


    SIGN_OUT: any;
    SIGN_OUT_SUCCEED: string;
    SIGN_OUT_FAILED: string;


    INIT: any;
    INIT_SUCCEED: string;
    INIT_FAILED: string;


}

export const actions: IUserReducerActions = {
    SIGN_IN: "USER:SIGN_IN",
    SIGN_IN_SUCCEED: "USER:SIGN_IN_SUCCEED",
    SIGN_IN_FAILED: "USER:SIGN_IN_FAILED",

    SIGN_OUT: "USER:SIGN_OUT",
    SIGN_OUT_SUCCEED: "USER:SIGN_OUT_SUCCEED",
    SIGN_OUT_FAILED: "USER:SIGN_OUT_FAILED",


    INIT: "USER:INIT",
    INIT_SUCCEED: "USER:INIT_SUCCEED",
    INIT_FAILED: "USER:INIT_FAILED",


};

export interface IUserData {
    user: IUser,
    responseMessage: string
}

export const defaultState: IUserData = {
    responseMessage: "",
    user: {
        userId: null,
        email: "",
        createdAt: "",
        customer: {
            customerId: null,
            name: "",
        }
    }
};

export default (state: IUserData = defaultState, {type, payload}: IUserActions): IUserData => {

    switch (type) {
        case actions.SIGN_IN:

            return {
                ...state,
                responseMessage: ""
            };

        case actions.SIGN_IN_SUCCEED: {

            const newUser = {...state.user};
            const newCustomer: ICustomer = {...newUser.customer};

            if (payload.user) {
                const user: IUser = payload.user;
                if (user) {
                    if (user.userId) {
                        newUser.userId = user.userId
                    }
                    if (user.email) {
                        newUser.email = user.email
                    }
                    if (user.createdAt) {
                        newUser.createdAt = user.createdAt
                    }
                    if (user.customer) {
                        const customer: ICustomer = user.customer;

                        if (customer.customerId) {
                            newCustomer.customerId = customer.customerId
                        }
                        if (customer.name) {
                            newCustomer.name = customer.name
                        }
                    }
                }
                newUser.customer = {
                    ...newCustomer
                };
                return {
                    ...state,
                    responseMessage: "",
                    user: {
                        ...newUser,
                    },
                };
            }
            return state;

        }
        case actions.SIGN_IN_FAILED:

            if (payload.message) {
                return {
                    ...state,
                    responseMessage: payload.message,
                };
            }

            return state;


        case actions.INIT_SUCCEED: {

            const newUser = {...state.user};

            if (payload.email) {
                newUser.email = payload.email
            }

            if (payload.userId) {
                newUser.userId = payload.userId
            }

            return {
                ...state,
                responseMessage: "",
                user: {
                    ...newUser,
                },
            };

        }
        case actions.INIT_FAILED:
            if (payload.message) {
                return {
                    ...state,
                    responseMessage: payload.message,
                }
            }
            return state;
        case actions.SIGN_OUT_SUCCEED: {
            return defaultState;
        }
        default:
            return state;
    }
};
