export default class Language {


    static languageNames = {
        "en": {
            locale: "en",
            name: "English",
        },
        "am": {
            locale: "am",
            name: "Հայերեն",
        },
    };

    static init(): any {
        if (!Language._instance) {
            Language._instance = new Language();
        }
        return Language._instance;
    }

    static get instance() {
        return this._instance;
    }

    private static _instance;
    private static _messages: any;

    static t(key: string) {
        return Language._messages[key]
    }

}
