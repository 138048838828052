import {createSelector} from "reselect";

const rootUserSelector: any = (state: any) => {
    return state.user;
};

export const emailSelector = () => createSelector(
    rootUserSelector, (user: any) => user.email
);

export const userIdSelector = () => {
    return createSelector(
        rootUserSelector, (userState: any) => {
            return userState.user.userId;
        }
    )
};

export const userSelector = () => {
    return createSelector(
        rootUserSelector, (userState: any) => {
            return userState.user;
        }
    )
};

export const responseMessageSelector = () => {
    return createSelector(
        rootUserSelector, (userState: any) => {
            return userState.responseMessage;
        }
    )
};
