import {actions} from "./UserReducer";
import {IUser} from "./UserTypes";

export interface IUserActions {
    type: string;
    payload: {
        responseMessage?: string;
        email?: string;
        password?: string;
        userId?: number;
        createdAt?: string;
        customerName?: string;
        customerId?: number;
        isRememberMeChecked?: boolean;
        message?: string;

        user?: IUser
    };
}


export function signInFailed(message: string): IUserActions {
    return {type: actions.SIGN_IN_FAILED, payload: {message}};
}

export function signInSucceed(user: IUser): IUserActions {
    return {type: actions.SIGN_IN_SUCCEED, payload: {user}};
}

export function signIn(email: string, password: string, isRememberMeChecked: boolean): IUserActions {
    return {type: actions.SIGN_IN, payload: {email, password, isRememberMeChecked}};
}


export function init(): IUserActions {
    return {type: actions.INIT, payload: {}};
}

export function initFailed(message: string): IUserActions {
    return {type: actions.INIT_FAILED, payload: {message}};
}

export function initSucceed(userId: number, email: string): IUserActions {
    return {type: actions.INIT_SUCCEED, payload: {userId, email}};
}


export function signOutFailed(message: string): IUserActions {
    return {type: actions.SIGN_OUT_FAILED, payload: {message}};
}

export function signOutSucceed(): IUserActions {
    return {
        type: actions.SIGN_OUT_SUCCEED, payload: {}
    };
}

export function signOut(): IUserActions {
    return {
        type: actions.SIGN_OUT, payload: {}
    };
}

