import {combineReducers} from "redux";

import userReducer from "../modules/user/UserReducer";
import appReducer from "../modules/app/AppReducer";

export default combineReducers({
    user: userReducer,
    app: appReducer,
});

