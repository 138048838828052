import {actions} from "./AppReducer";

export interface IAppActions {
    type: string;
    payload: {
        ping?: string;
    };
}


export function ping(): IAppActions {
    return {type: actions.PING, payload: {ping: ""}};
}

export function pingSucceed(ping: string): IAppActions {
    return {type: actions.PING_SUCCEED, payload: {ping}};
}

export function init(): IAppActions {
    return {type: actions.INIT, payload: {}};
}

export function initSucceed(): IAppActions {
    return {type: actions.INIT_SUCCEED, payload: {}};
}
