import {IAppActions} from "./AppActions";

interface IAppReducerActions {

    PING: any;
    PING_SUCCEED: string;

    INIT: any;
    INIT_SUCCEED: any;

}

export const actions: IAppReducerActions = {
    PING: "APP:PING",
    PING_SUCCEED: "APP:PING_SUCCEED",

    INIT: "APP:INIT",
    INIT_SUCCEED: "APP:INIT_SUCCEED"
};

export interface IAppData {
    ping: string,
    isInitialising: boolean,
    language: string
}

export const defaultState: IAppData = {
    ping: "",
    isInitialising: false,
    language: "en"
};

export default (state: IAppData = defaultState, {type, payload}: IAppActions): IAppData => {
    switch (type) {
        case actions.PING:
            return {
                ...state,
                ping: "",
            };

        case actions.PING_SUCCEED:
            if (payload.ping) {
                return {
                    ...state,
                    ping: payload.ping,

                };
            }
            return state;

        case actions.INIT:
            return {
                ...state,
                isInitialising: true,
            };

        case actions.INIT_SUCCEED:
            return {
                ...state,
                isInitialising: false,
            };

        default:
            return state;
    }
};
