import {createSelector} from "reselect";

const appSelector: any = (state: any) => {
    return state.app;
};


export const pongSelector = () => createSelector(
    appSelector, (app: any) => app.ping
);


export const initialisingSelector = () => createSelector(
    appSelector, (app: any) => app.isInitialising
);
