import *  as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.unregister();
