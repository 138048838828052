import {actions} from "./UserReducer";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    initFailed,
    initSucceed,
    IUserActions,
    signInFailed,
    signInSucceed,
    signOutFailed,
    signOutSucceed
} from "./UserActions";
import {initSucceed as appInitSucceed} from "../app/AppActions";
import UserRequests, {ISignInResult} from "./UserRequests";
import {IServersResponse} from "../../services/Request";
import {ICustomer, IUser} from "./UserTypes";

function* signInHandler({payload}: IUserActions) {
    try {

        const email = payload.email;
        const password = payload.password;
        const isRememberMeChecked = payload.isRememberMeChecked;

        const signInResult: IServersResponse<ISignInResult> = yield call(UserRequests.signIn, {
            email,
            password,
            isRememberMeChecked
        });

        if (signInResult.error) {
            if (signInResult.message) {
                yield put(signInFailed(signInResult.message))
            }
        } else {
            if (signInResult.result) {

                const userId: number = signInResult.result.userId;
                const email: string = signInResult.result.email;
                const createdAt: string = signInResult.result.createdAt;
                const customerId: number = signInResult.result.customer.customerId;
                const customerName: string = signInResult.result.customer.name;

                const customer: ICustomer = {
                    customerId,
                    name: customerName
                };

                const user: IUser = {
                    createdAt, customer, email, userId
                };

                yield put(signInSucceed(user));
            } else {
                yield put(signInFailed("UNKNOWN_ERROR"));
            }
        }
    } catch (e) {
        yield put(signInFailed(e.message));
    }
}

function* signOutHandler() {
    try {
        yield call(UserRequests.signOut);
        yield put(signOutSucceed());
    } catch (e) {
        yield put(signOutFailed(e.message));
    }
}

function* initHandler() {
    try {
        const signInResult: IServersResponse<ISignInResult> = yield call(UserRequests.retrieveLoggedUser);

        if (signInResult.error) {
            if (signInResult.message) {
                yield put(initFailed(signInResult.message))
            }
        } else {
            if (signInResult.result) {

                const userId: number = signInResult.result.userId;
                const email: string = signInResult.result.email;

                yield put(initSucceed(userId, email));
            } else {
                yield put(initFailed("UNKNOWN_ERROR"));
            }
        }
        yield put(appInitSucceed());
    } catch (e) {
        yield put(initFailed(e.message));
        yield put(appInitSucceed());
    }
}


function* userSaga(): any {
    yield takeLatest(actions.SIGN_IN, signInHandler);
    yield takeLatest(actions.SIGN_OUT, signOutHandler);
    yield takeLatest(actions.INIT, initHandler);
}

export default userSaga;
