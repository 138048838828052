
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import * as React from "react";
import {NavLink} from "react-router-dom";
import {IUser} from "../../modules/user/UserTypes";
import styled from 'styled-components';

const UnderNav = styled.div`
height: 56px;
`;

export default function SiteNavBar({user, doSignOut}: { user?: IUser; doSignOut: any }) {
    return (
        <React.Fragment>
            <Navbar fixed="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container fluid={true}>
                    <Navbar.Brand href="/">HyeCloud.dev</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <NavLink className={"nav-link"} to="/stats">Stats</NavLink>
                            <NavLink className={"nav-link"} to="/subscribers">Subscribers</NavLink>
                            <NavLink className={"nav-link"}
                                     to="/subscriptions">Subscriptions</NavLink>
                            <NavLink className={"nav-link"}
                                     to="/transactions">Transactions</NavLink>
                            <NavLink className={"nav-link"}
                                     to="/settings">Settings</NavLink>
                        </Nav>
                        <Nav>
                            <NavDropdown title={`Signed in as: ${user && user.email}`} id="basic-nav-dropdown">
                                <NavDropdown.Item href="" onClick={() => doSignOut()}>Sign out</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <UnderNav/>
        </React.Fragment>
    );

}
